import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EnComponent } from './en/en.component';
import { EnAboutMeComponent } from './en/en-about-me/en-about-me.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { EnSkillsComponent } from './en/en-skills/en-skills.component';

const routes: Routes = [
  { path: '', redirectTo: '/en/about-me', pathMatch: 'full' },
  { path: 'en', redirectTo: '/en/about-me', pathMatch: 'full' },
  {
    path: 'en', component: EnComponent, children: [
      { path: 'about-me', component: EnAboutMeComponent },
      { path: 'skills', component: EnSkillsComponent },
      { path: '**', component: PageNotFoundComponent }
    ]
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routedComponents = [
  EnComponent,
  EnAboutMeComponent,
  PageNotFoundComponent,
  EnSkillsComponent
]
