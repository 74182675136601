import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-en',
  templateUrl: './en.component.html',
  styleUrls: ['./en.component.css']
})
export class EnComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    /**
     * @function '' to add an event on Click to Sidebar-button
     */
    $(document).ready(function () {
      $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
      });
      $('.components li').on('click', function () {
        $('.components li').removeClass('active');
        $(this).addClass('active');
      });
    });
  }
}
